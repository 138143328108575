import React from 'react'
import { Link, graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ExpertsLogo from '../../assets/resident-experts/re-logo.svg'
import ShapeBlend from '../../assets/resident-experts/landing-overlay.svg'
import '../../styles/resident-experts/re-landing-banner.css'

export default function ReLandingBanner({ layoutPiece }) {
  const gatsbyImage = getImage(layoutPiece?.image?.image)
  return (
    <section className="resident-experts-page resident-experts-landing">
      <div className="re-left-cap">
        <div className="experts-logo">
          <ExpertsLogo/>
        </div>
        <div className="re-landing-content">
          <div className="experts-copy">
            {layoutPiece?.bodyText?.bodyText}
          </div>
          <a href="#re-form" className="experts-button">Register Now</a>
        </div>
        <div className="re-landing-background">
          <div className="background-gradient"></div>
          <div className="background-blending">
            <ShapeBlend className="blend-shape" />
          </div>
          <div className="background-mask">
            <GatsbyImage image={gatsbyImage} alt={layoutPiece?.image?.image?.description} className="re-clip-image" />
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  fragment ResidentExpertsLanding on ContentfulProductBanner {
    id
    headlineText
    bodyText {
      bodyText
    }
    image {
      fitToFrame
      image {
        gatsbyImageData(width: 900, placeholder: BLURRED)
        url
        description
      }
    }
    tagline
    mediaLinkButton {
      mediaLinkUrl
      mediaLinkCaption
      wistiaVideoId
    }
    button {
      buttonOutlined
      buttonText
      fullUrl
      slug
    }
    link {
      text
      fullUrl
      slug
    }
  }
`
