import * as React from 'react'
import { graphql } from 'gatsby'
//import component pieces
import ResidentExpertContentArea from '../templates/resident-expert-content-area'
import '../styles/resident-experts/re-global.css'
import ogMetaImage from '../../static/entratalogo_1200x630.png'

export default function ResidentExpertsTest({ data, pageContext }) {
  const layoutPieces = data.contentfulResidentExpertsNew.pageComponents
  console.log({data})

  return (
    <>
      <ResidentExpertContentArea layoutPieces={layoutPieces} />
    </>
  )
}

export const Head = ({data}) => (
  <>
    <meta name="description" content={data?.contentfulResidentExpertsPage?.metaDescription} />
    <title>{data?.contentfulResidentExpertsPage?.metaTitle}</title>
    <meta property="og:title" content={data?.contentfulResidentExpertsPage?.ogMetaTitle ? data.contentfulResidentExpertsPage.ogMetaTitle : data?.contentfulResidentExpertsPage?.metaTitle} />
    <meta property="og:description" content={data?.contentfulResidentExpertsPage?.ogMetaDescription ? data.contentfulResidentExpertsPage.ogMetaDescription : data?.contentfulResidentExpertsPage?.metaDescription} />
    <meta property="og:type" content={data?.contentfulResidentExpertsPage?.ogMetaType} />
    <meta property="og:url" content={data?.contentfulResidentExpertsPage?.slug ? `https://www.entrata.com${data?.contentfulResidentExpertsPage?.slug}` : undefined} />
    <meta property="og:image" content={data?.contentfulResidentExpertsPage?.ogMetaImage?.image ? data?.contentfulResidentExpertsPage?.ogMetaImage?.image?.url : ogMetaImage} />
  </>
)

export const query = graphql`
  query ResidentExpertsNewComponents($slug: String!) {
    contentfulResidentExpertsNew(slug: { eq: $slug }) {
      slug
      metadata {
        metaDescription
        metaTitle
        ogMetaTitle
        ogMetaDescription
        ogMetaType
      }
      pageComponents {
        ... on Node {
          internal {
            type
          }
          ... on ContentfulProductBanner {
            ...ResidentExpertsLanding
          }
          ... on ContentfulExpertsShowcaseFeature {
            ...ReExpertsComponent
          }
          ... on ContentfulResidentExpertsForm {
            ...ReFormsComponent
          }
          ... on ContentfulPortfolioCarousel {
            ...ReWebinarSlider
          }
        }
      }
    }
  }
`
