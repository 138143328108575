import * as React from "react"
import { Suspense } from "react"
//import component list
import ResidentExpertsLanding from '../components/resident-experts/re-landing-banner'
import LoadingSpinner from '../components/_loading-spinner'

const ExpertsShowcaseFeature = React.lazy(() => import('../components/resident-experts/re-experts-list'))
const ResidentExpertsForm = React.lazy(() => import('../components/resident-experts/re-form'))
const ResidentExpertsSlider = React.lazy(() => import('../components/resident-experts/re-slider'))

const ContentArea = ({layoutPieces}) => {
    if (layoutPieces) {
      return layoutPieces.map((layoutPiece, index) => {
        return ([
          layoutPiece.internal.type === 'ContentfulProductBanner' && <ResidentExpertsLanding layoutPiece={layoutPiece} key={index}/>,
          layoutPiece.internal.type === 'ContentfulExpertsShowcaseFeature' && <Suspense key={index} fallback={<LoadingSpinner />}><ExpertsShowcaseFeature layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulResidentExpertsForm' && <Suspense key={index} fallback={<LoadingSpinner />}><ResidentExpertsForm layoutPiece={layoutPiece} /></Suspense>,
          layoutPiece.internal.type === 'ContentfulPortfolioCarousel' && <Suspense key={index} fallback={<LoadingSpinner />}><ResidentExpertsSlider layoutPiece={layoutPiece} /></Suspense>
        ])
      })
    }
}

export default ContentArea
